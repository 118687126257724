import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { customAlphabet } from 'nanoid'
const nanoid = customAlphabet('12345678', 1)
import AOS from 'aos' //AOS - 1
import { enumData } from 'src/app/core'
import {} from '@fortawesome/free-solid-svg-icons'

import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons'
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  // font Awesome icon
  faFacebook = faFacebook
  faLinkedin = faLinkedin

  constructor(private router: Router) {}

  companyAddress: string = enumData.SettingString.COMPANY_ADDRESS.default
  companyPhone: string = enumData.SettingString.COMPANY_PHONE.default
  companyEmail: string = enumData.SettingString.COMPANY_EMAIL.default
  async ngOnInit() {
    AOS.init({ disable: 'mobile' }) //AOS - 2
    AOS.refresh()
  }
  handleNavigateTo(data: string) {
    this.router.navigate([`/${data}`])
  }

  // responsive
  //drawer
  visible = false

  open(): void {
    this.visible = true
  }

  close(): void {
    this.visible = false
  }
}
