import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { CoreService } from 'src/app/services'

@Component({
  selector: 'app-news',
  templateUrl: './news-layout.component.html',
  styleUrls: ['./news-layout.component.scss'],
})
export class NewsLayoutComponent implements OnInit {
  isCollapsed = false
  isExpandMenu: string = ''

  constructor(private router: Router, private coreService: CoreService) {}
  currentUser: any = {}
  setRole = new Set()

  ngOnInit(): void {}

  handleLogout() {}

  changePassword() {}

  handleExpand() {}
}
