import { DataType } from './enumKey'

export const enumData = {
  InputTypeArray: ['text', 'number', 'email', 'password', 'tel'],

  /** Kích thước tối đa tính bằng MB */
  maxSizeUpload: 10,
  Page: {
    pageIndex: 1,
    pageSize: 10,
    pageSizeMax: 1000000000,
    lstPageSize: [1, 2, 3, 4, 5, 10, 20, 30, 40, 50, 100],
    total: 0,
  },

  PhoneValidator: /^(0\d{9,10}|(\+84)[1-9]\d{8}|1[89]00\d{4}|\*\d{4})$/,
  Constants: {
    Model_Edit: 'Cập nhật',
    Model_Add: 'Thêm Mới',
    Message_Create_Success: 'Thêm Mới thành công!',
    Message_Update_Success: 'Cập nhật thành công!',
    Message_Delete_Success: 'Xoá thành công!',
    Message_Import_Success: 'Import excel thành công!',
    Message_Action_Success: 'Thao tác thành công!',
  },

  StatusFilter: {
    All: { value: 0, code: 'all', name: 'Tất cả' },
    Active: { value: false, code: 'active', name: 'Đang hoạt động' },
    InActive: { value: true, code: 'inactive', name: 'Ngưng hoạt động' },
  },

  Format: {
    /** yyyy */
    Year: 'yyyy',

    /** MM/yyyy */
    Month: 'MM/yyyy',

    /** dd/MM/yyyy */
    Date: 'dd/MM/yyyy',

    /** HH:mm dd/MM/yyyy */
    DateTime: 'HH:mm dd/MM/yyyy',

    /** dd/MM/yyyy HH:mm:ss */
    DateTimeFull: 'dd/MM/yyyy HH:mm:ss',

    /** HH:mm */
    Hour: 'HH:mm',

    /** HH:mm:ss */
    HourFull: 'HH:mm:ss',
  },

  BaseAction: {
    CREATE: { code: 'CREATE', name: 'Thêm Mới' },
    DOWN_EXCEL: { code: 'DOWN_EXCEL', name: 'Tải Về excel' },
    DOWN_TEMPLATE: { code: 'DOWN_TEMPLATE', name: 'Tải Mẫu' },
    IMPORT_EXCEL: { code: 'IMPORT_EXCEL', name: 'Nhập Excel' },
    VIEW: { code: 'VIEW', name: 'Xem Chi Tiết' },
    EDIT: { code: 'EDIT', name: 'Chỉnh Sửa' },
    DELETE: { code: 'DELETE', name: 'Xóa' },
    APPROVE: { code: 'APPROVE', name: 'Duyệt' },
    REJECT: { code: 'REJECT', name: 'Từ Chối' },
  },

  /** cấu hình động */
  SettingString: {
    COMPANY_NAME: {
      code: 'COMPANY_NAME',
      name: 'Tên Công Ty',
      default: 'Công Ty Cổ Phần EcomZone Việt Nam',
      description: 'Cấu Hình Tên Công Ty',
      type: DataType.string,
    },
    COMPANY_ADDRESS: {
      code: 'COMPANY_ADDRESS',
      name: 'Địa Chỉ Công Ty',
      default: '66/9 Đ. Phổ Quang, Phường 2, Tân Bình, Thành phố Hồ Chí Minh',
      description: 'Cấu Hình Địa chỉ Công Ty',
      type: DataType.string,
    },
    COMPANY_PHONE: {
      code: 'COMPANY_PHONE',
      name: 'Hotline Công Ty',
      default: '979072464',
      description: 'Cấu Hình Hotline Công Ty',
      type: DataType.string,
    },
    COMPANY_EMAIL: {
      code: 'COMPANY_EMAIL',
      name: 'Email Công Ty',
      default: 'hr@ecomzonevn.com',
      description: 'Cấu Hình Email Công Ty',
      type: DataType.string,
    },
    CONTACT_PERSON: {
      code: 'CONTACT_PERSON',
      name: 'Người Đại Diện Liên Lạc',
      default: 'Ms. Thư - 0979.072.464',
      description: 'Cấu hình người đại điện liên lạc',
      type: DataType.string,
    },
    COMPANY_URL_MAP: {
      code: 'COMPANY_URL_MAP',
      name: 'Địa Chỉ Google Map',
      default:
        'https://www.google.com/maps/search/66%2F9+Ph%E1%BB%95+Quang,+Ph%C6%B0%E1%BB%9Dng+2,+Qu%E1%BA%ADn+T%C3%A2n+B%C3%ACnh,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam?entry=gmail&source=g',
      description: 'Cấu hình địa chỉ Google Map',
      type: DataType.string,
    },
    CONFIG_DATE_SEND_NOTIFY: {
      code: 'CONFIG_DATE_SEND_NOTIFY',
      name: 'Cấu hình ngày để gửi thông báo nhân viên nghĩ việc cho BOD',
      default: 2,
      description: 'Cấu hình ngày để gửi thông báo nhân viên nghĩ việc cho BOD',
      type: DataType.int,
    },
    CONFIG_CODE_FOR_ZALO: {
      code: 'CONFIG_CODE_FOR_ZALO',
      name: 'Tạo code zalo để gửi thông báo qua zalo',
      default: '',
      description: 'Tạo code này mới có thể gửi thông báo qua zalo!',
      unit: '',
      type: DataType.string,
    },
    CONFIG_ACCESS_TOKEN: {
      code: 'CONFIG_ACCESS_TOKEN',
      name: 'Access token',
      default: '',
      description: 'Access token',
      unit: '',
      type: DataType.string,
    },
    CONFIG_REFRESH_TOKEN: {
      code: 'CONFIG_REFRESH_TOKEN',
      name: 'Refresh token',
      default: '',
      description: 'Refresh token',
      unit: '',
      type: DataType.string,
    },
    CONFIG_LATE_NOT_PERMISSION: {
      code: 'CONFIG_LATE_NOT_PERMISSION',
      name: 'Cấu hình đi trể không xin phép',
      default: 3,
      description: 'Cấu hình số lần đi trể không xin phép',
      type: DataType.int,
    },
    CONFIG_LEAVE_WITHOUT_PERMISSION: {
      code: 'CONFIG_LEAVE_WITHOUT_PERMISSION',
      name: 'Cấu hình nghĩ việc không xin phép',
      default: 3,
      description: 'Cấu hình số lần nghĩ việc không xin phép',
      type: DataType.int,
    },
    CONFIG_BANK_1: {
      code: 'CONFIG_BANK_1',
      name: 'Techcombank',
      default: '071020',
      description: 'Số tài khoản Techcombank',
      type: DataType.string,
    },
    CONFIG_BANK_2: {
      code: 'CONFIG_BANK_2',
      name: 'ACB',
      default: '866888666688',
      description: 'Số tài khoản ACB',
      type: DataType.string,
    },
    CONFIG_BANK_3: {
      code: 'CONFIG_BANK_3',
      name: 'MBBank',
      default: '585979999',
      description: 'Số tài khoản MBBank',
      type: DataType.string,
    },
    CONFIG_BANK_4: {
      code: 'CONFIG_BANK_4',
      name: 'ACB',
      default: '85655667788',
      description: 'Số tài khoản ACB USD',
      type: DataType.string,
    },
  },

  /** King Queen */
  KingQueenType: {
    KING: {
      code: 'KING',
      name: 'KING',
    },
    QUEEN: {
      code: 'QUEEN',
      name: 'QUEEN',
    },
  },
}
