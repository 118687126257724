import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { NzButtonModule } from 'ng-zorro-antd/button'
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component'
import { NewsLayoutComponent } from './layouts/news-layout/news-layout.component'
const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    loadChildren: () =>
      import('./layouts/home-layout/home-layout.module').then(m => m.HomeLayoutModule),
  },
  {
    path: 'news',
    component: NewsLayoutComponent,
    loadChildren: () =>
      import('./layouts/news-layout/news-layout.module').then(m => m.NewsLayoutModule),
  },
]

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes), NzButtonModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
