<div class="header">
  <div class="left">
    <div class="date">
      <div class="day">
        <span>{{ currentDay | uppercase }}</span>
      </div>
      <div class="dot">.</div>
      <div class="month">
        <span>{{ currentDate | uppercase }}</span>
        <span class="dot">-</span>
        <span>{{ currentMonth | uppercase }}</span>
      </div>
      <div class="dot">.</div>
      <div class="year">
        <span>{{ currentYear | uppercase }}</span>
      </div>
    </div>
    <div class="social">
      <a (click)="handleNavigateTo('/')" target="_blank">
        <span nz-icon nzType="home" nzTheme="outline"></span>
      </a>
      <a href="https://www.facebook.com/profile.php?id=61553143814539" target="_blank">
        <span nz-icon nzType="facebook" nzTheme="outline"></span>
      </a>
      <span nz-icon nzType="twitter" nzTheme="outline"></span>
      <span nz-icon nzType="instagram" nzTheme="outline"></span>
      <span nz-icon nzType="linkedin" nzTheme="outline"></span>
    </div>
  </div>
  <div (click)="handleNavigateTo('/news')" class="middle">
    <i>
      <img src="../../../assets/images/logo-name-bg.png" alt="" />
    </i>
    <span class="your-new">CHÀO MỪNG ĐẾN VỚI ECOMZONE!</span>
  </div>
  <div class="right-container">
    <div class="right">
      <!-- <span>liên Hệ</span> -->
      <!-- <span class="login">LOGIN</span> -->
      <!-- <span class="register">REGISTER</span> -->
      <!-- <span class="language">VI</span> -->
    </div>
  </div>
</div>
