import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { enumData } from 'src/app/core'
import { Location } from '@angular/common'
import { NotificationService } from 'src/app/services'
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons'

@Component({
  selector: 'app-footer-news',
  templateUrl: './footer-news.component.html',
  styleUrls: ['./footer-news.component.scss'],
})
export class FooterNewsComponent implements OnInit {
  // font Awesome icon
  faFacebook = faFacebook
  faLinkedin = faLinkedin

  data: any
  mainNews: any
  secondNews: any[] = []
  otherNews: any[] = []
  companyAddress: string = enumData.SettingString.COMPANY_ADDRESS.default
  companyPhone: string = enumData.SettingString.COMPANY_PHONE.default
  companyEmail: string = enumData.SettingString.COMPANY_EMAIL.default
  constructor(private router: Router, protected notificationService: NotificationService) {}
  currentUrl!: string
  currentDate!: any
  currentDay!: string
  currentMonth!: string
  currentYear!: string
  async ngOnInit() {}
  handleNavigateTo(data: string) {
    this.router.navigate([`/${data}`])
  }
}
