import { Injectable } from '@angular/core'
import { Column, Workbook } from 'exceljs'
import * as moment from 'moment'
import { NzModalService } from 'ng-zorro-antd/modal'
import { ApiService, NotificationService } from '.'
import { enumData } from '../core/enumData'
const fs = require('file-saver')

@Injectable()
export class CoreService {
  constructor(
    private notificationService: NotificationService,
    private apiService: ApiService,
    private modal: NzModalService,
  ) {}

  public sumArray(arr: any, prop: any) {
    let total = 0
    for (let i = 0, len = arr.length; i < len; i++) {
      total += +arr[i][prop]
    }
    return total
  }

  public groupByArray(data: any, key: any) {
    const groupedObj = data.reduce((prev: any, cur: any) => {
      if (!prev[cur[key]]) {
        prev[cur[key]] = [cur]
      } else {
        prev[cur[key]].push(cur)
      }
      return prev
    }, {})
    return Object.keys(groupedObj).map((Heading: any) => ({
      heading: Heading,
      list: groupedObj[Heading],
    }))
  }

  /** Sort từ nhỏ tới lớn */
  public dynamicSort(data: any[], key: any) {
    return data.sort(this.sortArray(key))
  }

  public sortArray(key: any) {
    let sortOrder = 1
    if (key[0] === '-') {
      sortOrder = -1
      key = key.substr(1)
    }
    return (a: any, b: any) => {
      const result = a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0
      return result * sortOrder
    }
  }

  public convertObjToArray(obj: any) {
    const arr = []
    // tslint:disable-next-line:forin
    for (const key in obj) {
      const value = obj[key]
      arr.push(value)
    }
    return arr
  }

  /** chuyển enum thành option của select box */
  public convertEnumToSelectOption(enumObj: any) {
    const arr = []
    for (const key in enumObj) {
      const value = enumObj[key]
      let selectOption: any = {}
      selectOption.label = value['name']
        ? value['name']
        : value['label']
        ? value['label']
        : value['title']
        ? value['title']
        : value['key']
        ? value['key']
        : null
      selectOption.value = value['code']
        ? value['code']
        : value['value']
        ? value['value']
        : value['default']
        ? value['default']
        : value['id']
        ? value['id']
        : null
      arr.push(selectOption)
    }
    return arr
  }

  /**
   * Lọc properties không có giá trị
   *
   * @author senhoang
   * @param {Object} where - Đối tượng cần lọc.
   * @returns {Object} Hàm trả về obj đã sử lý. Nó cũng sửa đổi đối tượng được truyền làm tham số tại chỗ.
   */
  public filterDataSearch(where: any) {
    return Object.keys(where).forEach((k: any) => {
      if (typeof where[k] === 'object' && where[k] && Object.keys(where[k]).length === 0) {
        delete where[k] // xóa đối tượng rỗng
      } else if (Array.isArray(where[k]) && where[k] && where[k].length === 0) {
        delete where[k] // xóa mảng rỗng
      } else if (!(where[k] || where[k] === false || where[k] === 0)) {
        delete where[k] // xóa các giá trị không đúng
      }
    })
  }

  public getEnumName(nameEnum: string, value: string) {
    return this.getEnumElement(nameEnum, value, 'code', 'name')
  }

  public getEnumNameByValue(nameEnum: string, value: string) {
    return this.getEnumElement(nameEnum, value, 'value', 'name')
  }

  public getEnumElement(nameEnum: string, value: string, keyIn: string, keyOut: string) {
    const enumTemp: any = enumData
    const data = this.convertObjToArray(enumTemp[nameEnum])
    const item = data.find(p => p[keyIn] === value)
    return item && item[keyOut] ? item[keyOut] : ''
  }

  public getEnumColor(nameEnum: string, value: string) {
    return this.getEnumElement(nameEnum, value, 'code', 'color')
  }

  getLanguage() {
    if (localStorage.getItem('lang')) {
      let lang = localStorage.getItem('language')
      if (lang) {
        let value = JSON.parse(lang ? lang : '')
        let object = value.reduce(
          (obj: any, item: any) => Object.assign(obj, { [item.key]: item.value }),
          {},
        )
        return object
      }
    }
  }

  isNumber(num: any) {
    if (!isNaN(num) && !isNaN(Number(num))) {
      let _int = num % 1 === 0
      let _float = num % 1 !== 0

      if (_int) {
        return true //'int'
      }
      if (_float) {
        return true //'float'
      }
      return false //'string'
    }
    return false //'string'
  }

  isValidDate(dateString: any) {
    // First check for the pattern
    if (
      !/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
        dateString,
      )
    )
      return false
    // Parse the date parts to integers
    var parts = dateString.split('/')
    var day = parseInt(parts[0], 10)
    var month = parseInt(parts[1], 10)
    var year = parseInt(parts[2], 10)

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12) return false

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1]
  }

  // covert ngày nhập excel
  excelDateToJSDate(day: any) {
    var utc_days = Math.floor(day - 25569)
    var utc_value = utc_days * 86400
    var date_info = new Date(utc_value * 1000)

    var fractional_day = day - Math.floor(day) + 0.0000001

    var total_seconds = Math.floor(86400 * fractional_day)

    var seconds = total_seconds % 60

    total_seconds -= seconds

    var hours = Math.floor(total_seconds / (60 * 60))
    var minutes = Math.floor(total_seconds / 60) % 60

    return new Date(
      date_info.getFullYear(),
      date_info.getMonth(),
      date_info.getDate(),
      hours,
      minutes,
      seconds,
    )
  }

  isValidDateTime(dateString: any) {
    // First check for the pattern
    // if (!/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(dateString)) return false
    // if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false
    if (!/^\d{1,2}\/\d{1,2}\/\d{4} ([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/.test(dateString))
      return false
    // Parse the date parts to integers
    var parts = dateString.split(' ')

    var parts1 = parts[0].split('/')
    var day = parseInt(parts1[0], 10)
    var month = parseInt(parts1[1], 10)
    var year = parseInt(parts1[2], 10)

    var parts2 = parts[1].split(':')
    var hour = parseInt(parts2[0], 10)
    var minute = parseInt(parts2[1], 10)
    var second = parseInt(parts2[2], 10)

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12) return false

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1] && hour < 24 && minute < 60 && second < 60
  }

  convertToDate(d: any) {
    if (Object.prototype.toString.call(d) === '[object Date]') {
      d = moment(d, 'DD/MM/YYYY').toDate()
      if (isNaN(d)) {
        return null
      } else {
        return new Date(d)
      }
    } else if (Object.prototype.toString.call(d) === '[object Number]') {
      d = this.excelDateToJSDate(d)
      if (isNaN(d)) {
        return null
      } else {
        return d
      }
    } else if (Object.prototype.toString.call(d) === '[object String]') {
      if (this.isValidDate(d) == true) {
        var dateParts = d.split('/')
        var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
        return dateObject
      } else {
        return null
      }
    } else {
      return null
    }
  }

  convertToDateTime(d: any) {
    if (Object.prototype.toString.call(d) === '[object Date]') {
      d = moment(d, 'DD/MM/YYYY HH:mm').toDate()
      if (isNaN(d)) {
        return null
      } else {
        return new Date(d)
      }
    } else if (Object.prototype.toString.call(d) === '[object Number]') {
      d = this.excelDateToJSDate(d)
      if (isNaN(d)) {
        return null
      } else {
        return d
      }
    } else if (Object.prototype.toString.call(d) === '[object String]') {
      if (this.isValidDateTime(d) == true) {
        var dateParts = d.split(' ')[0].split('/')
        var timeParts = d.split(' ')[1].split(':')
        var dateObject = new Date(
          +dateParts[2],
          dateParts[1] - 1,
          +dateParts[0],
          timeParts[0],
          timeParts[1],
          timeParts[2],
        )
        return dateObject
      } else {
        return null
      }
    } else {
      return null
    }
  }

  public distance(pFrom: any, pTo: any) {
    // Return Meter and Second between 2 points
    var obj = { Distance: 0, Time: 0 }
    if (pFrom && pTo) {
      if (pFrom.lat > 0 && pFrom.lng > 0 && pTo.lat > 0 && pTo.lng > 0) {
        var R = 6371
        var dLat = (pTo.lat - pFrom.lat) * (Math.PI / 180.0)
        var dLon = (pTo.lng - pFrom.lng) * (Math.PI / 180.0)
        var lat1 = pFrom.lat * (Math.PI / 180.0)
        var lat2 = pTo.lat * (Math.PI / 180.0)
        var a =
          Math.pow(Math.sin(dLat / 2), 2) +
          Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2)
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        obj.Distance = R * c * 1000 // Meter
        obj.Time = (obj.Distance / 30) * 60 * 60 // Second
      }
    }
    return obj
  }

  isEmail(email: string) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  }

  public groupDataLst<T extends Record<string, any>>(
    lst: T[],
    keyGroup: { [key: string]: 'sum' | 'count' | 'unique' | 'countUnique' },
  ): Record<string, any> {
    const result: any = {}
    for (const key in keyGroup) {
      result[key] = keyGroup[key] === 'sum' ? 0 : []
    }
    // LOOP DATA
    for (const item of lst) {
      for (const key in result) {
        if (keyGroup[key] === 'sum') {
          result[key] += +item[key] || 0
        } else {
          if (item[key] instanceof Array) {
            result[key].push(...item[key])
          } else {
            result[key].push(item[key])
          }
        }
      }
    }

    //GET UNIQUE
    for (const key in result) {
      if (keyGroup[key] === 'unique') {
        result[key] = result[key]?.filter((e: any) => e)
        result[key] = [...new Set(result[key])]
      }
    }

    return result
  }

  /**
   * Hàm Convert array to object (HashMap) [{id,name}] => {id:{id,name}}
   *
   * @author senhoang
   * @param {array} arr  - List Array muốn convert to hash map.
   * @param {string} key - Key để làm key trong hash map(mặc định là id)
   */
  public arrayToObject(arr: any[], key: string = 'id') {
    return arr && arr.length > 0
      ? arr.reduce(
          (a, v) =>
            a[v[key]] ? { ...a, [v[key]]: { ...a[v[key]], ...v } } : { ...a, [v[key]]: v },
          {},
        )
      : {}
  }

  /** Render html pop up string for marker */
  public generateOrderInfoHtml<
    U extends { groupInfor: T[]; images?: I[] },
    T extends { tittle: string; lstInfor: K[] },
    K extends { name: string; value: string },
    I extends { src: string; name?: string },
  >(data: U): string {
    //#region Marker infor version 1
    //   return `
    //   <div style="display: flex; flex-wrap: wrap;">
    //     <div style="flex: 1; min-width: 200px; max-width: 50%; padding: 10px; background-color: #f2f2f2;">
    //       ${data.groupInfor
    //         .map(
    //           (groupInfor) => `
    //           <h2>${groupInfor.tittle}</h2>
    //           ${groupInfor.lstInfor
    //             .map(
    //               (infor) => `
    //               <p><strong><b>${infor.name}:</b></strong> ${infor.value}</p>
    //           `,
    //             )
    //             .join('')}
    //       `,
    //         )
    //         .join('')}
    //     </div>
    //     <div style="flex: 1; min-width: 200px; max-width: 50%; padding: 10px; background-color: #f2f2f2;">
    //       <h2 style="color:'#F00';">HÌNH ẢNH</h2>
    //       <div style="display: flex; flex-wrap: wrap; justify-content: space-between;">
    //         ${data.images
    //           .map(
    //             (image: any) => `
    //           <img style="flex-basis: calc(50% - 10px); height: 70px; margin: 5px;" src="${image.src}" alt="${image.name || 'Hình ảnh'}">
    //         `,
    //           )
    //           .join('')}
    //       </div>
    //     </div>
    //   </div>
    // `
    //#endregion

    return `    
      <div style="display: flex; flex-wrap: wrap;">
        <div style="
          flex: 1;
          min-width: 200px;
          max-width: ${data.images ? (data.images?.length === 0 ? 70 : 50) : 100}%;
          background-color: #f2f2f2;
          ${
            data.images
              ? 'border-right: 1px #e5e5e5 solid; padding: 10px 8px 10px 10px;'
              : 'padding: 10px;'
          }
        ">
          ${data.groupInfor
            .map(
              (groupInfor, idxGr: number) => `
              <div 
                style="
                  ${
                    idxGr + 1 === data.groupInfor?.length
                      ? ''
                      : 'border-bottom: 1px #e5e5e5 solid;margin-bottom: 2px;'
                  }
                  ${idxGr === 0 ? '' : 'padding-top: 10px;'}
                ">
                <h2 style="text-align: center">${groupInfor.tittle}</h2>
                ${groupInfor.lstInfor
                  .map(
                    infor => `
                    <p style="margin-bottom: 1em;"><strong><b>${infor.name}:</b></strong> ${infor.value}</p>
                `,
                  )
                  .join('')}
              </div>
          `,
            )
            .join('')}

        </div>
        <div 
          style="${data.images ? '' : 'display: none !important;'}
            flex: 1; min-width: 200px; max-width: ${
              data.images ? (data.images?.length === 0 ? 20 : 50) : 0
            }%; 
            padding: 10px; background-color: #f2f2f2;"
          >
          <h2 style="color:'#F00'; text-align: center;">HÌNH ẢNH</h2>
          <div style="display: flex; flex-wrap: wrap; justify-content: space-between;">
            ${
              data.images
                ? data.images
                    .map(
                      (image: any) => `
              <img style="flex-basis: calc(50% - 10px); height: 70px; margin: 5px;" src="${
                image.src
              }" alt="${image.name || 'Hình ảnh'}">
            `,
                    )
                    .join('')
                : ''
            }
          </div>
        </div>
      </div>
    `
  }

  /**
   * Finds the minimum and maximum value of a given key in an array of objects.
   *
   * @param arr The array of objects to search.
   * @param key The key in the objects to find the minimum and maximum value.
   * @returns An array containing the minimum and maximum value of the key.
   * @template T The type of objects in the array.
   * @template K The type of key in the objects.
   * @throws An error if the key is not a valid key in the objects.
   *
   * @example
   * const data = [
   *   { name: 'A', value: 10 },
   *   { name: 'B', value: 20 },
   *   { name: 'C', value: 15 },
   *   { name: 'D', value: 5 },
   * ];
   *
   * const [minValue, maxValue] = getMinMaxValue(data, 'value');
   * console.log(`Min value: ${minValue}, Max value: ${maxValue}`); // Min value: 5, Max value: 20
   */
  public getMinMaxValue<T extends Record<K, number | string | Date | any>, K extends keyof T>(
    arr: T[],
    key: K,
  ): [T[K], T[K]] {
    let minCompare: any = arr[0][key]
    let maxCompare: any = arr[0][key]

    let primitiveMin = arr[0][key]
    let primitiveMax = arr[0][key]

    // check is date
    const testDateMin = this.convertToDateString(minCompare)
    if (testDateMin) {
      minCompare = testDateMin
      maxCompare = testDateMin
    }

    for (let i = 1; i < arr.length; i++) {
      let primitiveValue = arr[i][key]

      let valueCompare: any = arr[i][key]
      const testDateValue = this.convertToDateString(valueCompare)
      if (testDateValue) {
        valueCompare = testDateValue
      }

      if (valueCompare < minCompare) {
        minCompare = valueCompare
        primitiveMin = primitiveValue
      }
      if (valueCompare > maxCompare) {
        maxCompare = valueCompare
        primitiveMax = primitiveValue
      }
    }
    console.log(moment(primitiveMin).format('DD/MM/YYYY HH:mm'))
    console.log(moment(primitiveMax).format('DD/MM/YYYY HH:mm'))
    return [primitiveMin, primitiveMax]
  }

  private convertToDateString(date: string | Date): boolean | string | Date {
    // Convert to date if is date
    if (!isNaN(Date.parse(date as string))) {
      const newDate = new Date(date as string)
      const fmtDate = moment(newDate).format('YYYY-MM-DD HH:mm:ss')

      return fmtDate
    } else {
      return false
    }
  }

  /* Popup confirm */
  public showConfirm(notify: string, content?: any, callBack?: any) {
    const customOption: any = {}

    // Add body confirm
    if (content) customOption.nzContent = content

    this.modal.confirm({
      nzTitle: `<i>${notify}</i>`,
      ...customOption,
      nzOnOk: callBack,
      nzFooterAlign: 'center',
    })
  }

  public deepCopy<T>(obj: T): T {
    const jsonString = JSON.stringify(obj)
    const copiedObj = JSON.parse(jsonString)
    return copiedObj as T
  }

  public deepCopyArr(arr: any[]): any[] {
    const resultArr: any[] = []

    for (const obj of arr) {
      resultArr.push(this.deepCopy(obj))
    }

    return resultArr
  }

  /** 2 ngày trừ nhau ra số tháng */
  public diffMonth(df: Date, dt: Date) {
    var months
    months = (df.getFullYear() - dt.getFullYear()) * 12
    months -= dt.getMonth()
    months += df.getMonth()
    return Math.round(months * 10) / 10
  }

  /** 2 ngày trừ nhau ra số ngày */
  public diffDay(df: Date, dt: Date) {
    var days = (df.getTime() - dt.getTime()) / (1000 * 60 * 60 * 24)
    return Math.round(days * 10) / 10
  }

  /** 2 ngày trừ nhau ra số giờ nguyên */
  public diffHour(df: Date, dt: Date) {
    var diff = (df.getTime() - dt.getTime()) / 1000
    diff /= 60 * 60
    return Math.abs(Math.round(diff * 10) / 10)
  }

  /** 2 ngày trừ nhau ra số giờ bao gồm cả thập phân */
  public diffHourPositive(df: Date, dt: Date) {
    var diff = (df.getTime() - dt.getTime()) / 1000
    diff /= 60 * 60
    diff = diff > 0 ? diff : 0
    return Math.round(diff * 10) / 10
  }

  /** 2 ngày trừ nhau ra số phút */
  public diffMinute(df: Date, dt: Date) {
    var diff = (df.getTime() - dt.getTime()) / 1000
    diff /= 60 * 60
    diff = diff > 0 ? diff : 0
    return Math.round(diff * 10) / 10
  }

  /** Lấy số ngày trong tháng */
  public dateInMonth(month: Date) {
    let lstSaturday = []
    let lstSunday = []
    let lstDate = []
    const lastMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0, 23, 59, 59, 59)
    for (var i = 1; new Date(month.getFullYear(), month.getMonth(), i) <= lastMonth; i++) {
      var date = new Date(month.getFullYear(), month.getMonth(), i)
      if (date.getMonth() == month.getMonth()) {
        if (date.getDay() == 0) {
          lstSunday.push(date)
        } else if (date.getDay() == 6) {
          lstSaturday.push(date)
        }
        lstDate.push(date)
      }
    }
    return { lstDate, lstSaturday, lstSunday }
  }

  public random(length: number) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length
    let counter = 0
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
      counter += 1
    }
    return result
  }

  public filterAndRemovePrefix(arr: string[], prefix: 'hrm' | 'wms' | 'portal'): string[] {
    const filteredArr: string[] = []
    for (const item of arr) {
      if (item.startsWith(prefix + '/')) {
        filteredArr.push(item.replace(prefix + '/', ''))
      }
    }
    return filteredArr
  }

  // filterRouting(lst: CustomRoutes, lstRouting: string[]): CustomRoutes {
  //   // lstEndRoutingCanAccess
  //   const lstReturn: CustomRoutes = []
  //   const setRoute = new Set<string>(lstRouting)

  //   /* LV0 */
  //   for (const route0 of lst) {
  //     if (route0.isNotShow) continue
  //     const customeRoute0 = { ...route0 }
  //     let isPush0 = false
  //     if (customeRoute0.path && !customeRoute0.children && setRoute.has(customeRoute0.path)) {
  //       isPush0 = true
  //     } else {
  //       // Check lstCon v1
  //       if (customeRoute0.children) {
  //         /* LV1 */
  //         const lstChild1 = []
  //         for (const route1 of customeRoute0.children || []) {
  //           const customeRoute1 = { ...route1 }
  //           const urlRoute1 = route0.path + (route1.path == '/' ? '' : '/' + route1.path)
  //           let isPush1 = false
  //           if (customeRoute1.path && !customeRoute1.children && setRoute.has(urlRoute1)) {
  //             isPush1 = true
  //           } else {
  //             if (customeRoute1.children) {
  //               /* LV2 */
  //               const lstChild2 = []
  //               for (const route2 of customeRoute1.children || []) {
  //                 //
  //                 const customeRoute2 = { ...route2 }
  //                 const urlRoute2 =
  //                   route0.path +
  //                   (route1.path == '/' ? '' : '/' + route1.path) +
  //                   (route2.path == '/' ? '' : '/' + route2.path)
  //                 let isPush2 = false
  //                 if (customeRoute2.path && !customeRoute2.children && setRoute.has(urlRoute2)) {
  //                   isPush2 = true
  //                 } else {
  //                 }
  //                 isPush2 && lstChild2.push(customeRoute2)
  //               }
  //               if (lstChild2?.length > 0) {
  //                 customeRoute1.children = lstChild2
  //                 isPush1 = true
  //               }
  //             }
  //           }
  //           isPush1 && lstChild1.push(customeRoute1)
  //         }
  //         if (lstChild1?.length > 0) {
  //           customeRoute0.children = lstChild1
  //           isPush0 = true
  //         }
  //       }
  //     }

  //     isPush0 && lstReturn.push(customeRoute0)
  //   }

  //   return lstReturn
  // }

  // filterSearchRoute(lstRoute: CustomRoutes) {
  //   return lstRoute
  //     .map(route => {
  //       return route.children
  //         ?.filter(x => !x.isNotShow)
  //         .map(item => {
  //           return {
  //             parentLabel: route.label,
  //             label: item.label,
  //             children: item.children,
  //             parentPath: route.path,
  //             path: item.path,
  //           }
  //         })
  //     })
  //     .flat()
  //     .map(route =>
  //       route?.children
  //         ? route.children.map(item => ({
  //             label: route?.parentLabel + ' > ' + item.label,
  //             value: route.parentPath + '/' + item.path,
  //           }))
  //         : {
  //             label: `${route?.parentLabel} > ${route?.label}`,
  //             value: `${route?.parentPath}${route?.path !== '/' ? '/' + route?.path : ''}`,
  //           },
  //     )
  //     .flat()
  // }

  isNotEmptyObject(obj: object): boolean {
    return Object.keys(obj).length > 0
  }

  getFirstLastInWeek(date: Date) {
    const first = date.getDate() - date.getDay() // First day is the day of the month - the day of the week
    const last = first + 5 // last day is the first day + 6

    let firstday = new Date(date.setDate(first))
    let lastday = new Date(date.setDate(last))

    firstday = new Date(firstday.setHours(0, 0, 0, 0))
    lastday = new Date(lastday.setHours(23, 59, 59, 9999))

    return [firstday, lastday]
  }

  isJSON(str: any) {
    try {
      JSON.parse(str)
      return true
    } catch (e) {
      return false
    }
  }
}
