export const ERROR_NOT_FOUND_DATA = 'Không tìm thấy dữ liệu !'
export const ERROR_CODE_TAKEN = 'Mã đã được sử dụng !'
export const ERROR_PHONE_TAKEN = 'Số Điện Thoại đã được sử dụng !'
export const ERROR_NAME_TAKEN = 'Tên đã được sử dụng !'
export const ERROR_USERNAME_DUPLICATE = 'Tài khoản đã được sử dụng !'
export const ERROR_YOU_DO_NOT_HAVE_PERMISSION = 'Bạn không có quyền !'

export const CREATE_SUCCESS = 'Thêm mới thành công !'
export const UPDATE_SUCCESS = 'Cập nhật thành công !'
export const APPROVED_SUCCESS = 'Duyệt thành công !'
export const REJECT_SUCCESS = 'Hoàn tất [ Từ Chối ] thành công !'
export const UPDATE_ACTIVE_SUCCESS = 'Cập nhật kích hoạt thành công !'
export const UPDATE_STATUS_SUCCESS = 'Cập nhật trạng thái thành công !'
export const DELETE_SUCCESS = 'Xoá thành công !'
export const IMPORT_SUCCESS = 'Tải dữ liệu lên thành công !'
export const IMPORT_FAIL = 'Tải dữ liệu lên Thất bại !'
export const ACTION_SUCCESS = 'Thao tác thành công !'

export enum ControlType {
  empty = 'empty', // empty
  divider = 'divider', // divider
  text = 'text', // input type text
  number = 'number', // input type number
  email = 'email', // input type email
  password = 'password', // input type password
  textarea = 'textarea', // textarea
  select = 'select', // nz-select
  // multiSelect = 'multiSelect', // nz-select multi
  date = 'date', // nz-date
  time = 'time', // nz-time
  range = 'range', // nz-range
  checkbox = 'checkbox', // input type checkbox
  image = 'image', // nz-upload image
  radio = 'radio', // nz-radio-group
  tag = 'tag', // nz-tag
  switch = 'switch', // nz-switch
  file = 'file', // nz-upload
  tel = 'tel',
  label = 'label',
  lstFile = 'lstFile', // nz-upload
  editor = 'editor', // editor
  sigleImage = 'sigleImage', // nz-upload
  currency = 'currency', // nz-upload

  sigleImageFontCMND = 'sigleImageFontCMND', // nz-upload
  sigleImageBackCMND = 'sigleImageBackCMND', // nz-upload

  sigleImageAssetBefore = 'sigleImageAssetBefore',

  link = 'link',
  json = 'json',
}

export enum DateModeType {
  week = 'week',
  year = 'year',
  month = 'month',
}

export enum SelectModeType {
  multiple = 'multiple',
  tags = 'tags',
  default = 'default',
}

export enum SearchHeaderType {
  text = 'text',
  select = 'select',
  date = 'date',
  dateRange = 'date-range',
  numberRange = 'number-range',
}

export enum TextTransform {
  capitalize = 'capitalize',
  uppercase = 'uppercase',
  lowercase = 'lowercase',
}

export enum DataType {
  string = 'string',
  int = 'int',
  float = 'float',
  date = 'date',
  dateTime = 'dateTime',
  time = 'time',
  boolean = 'boolean',
}

export enum BaseAction {
  CREATE = 'CREATE',
  DOWN_EXCEL = 'DOWN_EXCEL',
  DOWN_TEMPLATE = 'DOWN_TEMPLATE',
  IMPORT_EXCEL = 'IMPORT_EXCEL',
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
}

export enum KingQueenType {
  KING = 'KING',
  QUEEN = 'QUEEN',
}
