import { APP_INITIALIZER, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import vi from '@angular/common/locales/vi'
import { FormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { EditorModule } from '@tinymce/tinymce-angular'
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb'
import { NZ_CONFIG } from 'ng-zorro-antd/core/config'
import { NZ_I18N, vi_VN } from 'ng-zorro-antd/i18n'
import { NzLayoutModule } from 'ng-zorro-antd/layout'
import { NzMenuModule } from 'ng-zorro-antd/menu'
import { NzModalModule } from 'ng-zorro-antd/modal'
import { ClipboardModule } from 'ngx-clipboard'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { IconsProviderModule } from './icons-provider.module'
import { MaterialModule } from './material.module'
import { CoreService, NotificationService, SocketService } from './services'
import { ImagesService } from './services/images.service'

import { OverlayContainer } from '@angular/cdk/overlay'
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular'
import { NzNotificationModule } from 'ng-zorro-antd/notification'
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io'
import { environment } from './environments/environment'
import { InAppRootOverlayContainer } from './in-app-root-overlay-container'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { ViewportScroller } from '@angular/common'
import { Router, Scroll } from '@angular/router'
registerLocaleData(vi)
const config: SocketIoConfig = {
  url: environment.baseUrl,
  options: {},
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    SocketIoModule.forRoot(config),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    NzBreadCrumbModule,
    MaterialModule,
    NzModalModule,
    CKEditorModule,
    ClipboardModule,
    EditorModule,
    NzNotificationModule,
    FontAwesomeModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: vi_VN },
    // { provide: NZ_DATE_LOCALE, useValue: dateVi },
    { provide: NZ_CONFIG, useValue: { notification: { nzDuration: 2000 } } },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    { provide: OverlayContainer, useClass: InAppRootOverlayContainer },
    {
      provide: APP_INITIALIZER,
      useFactory: (router: Router, viewportScroller: ViewportScroller) => {
        return () => {
          router.events.subscribe(event => {
            if (event instanceof Scroll) {
              if (event.position) {
                // cuộn đến vị trí đã lưu
                viewportScroller.scrollToPosition(event.position)
              } else {
                // cuộn lên đầu trang
                viewportScroller.scrollToPosition([0, 0])
              }
            }
          })
        }
      },
      multi: true,
      deps: [Router, ViewportScroller],
    },
    NotificationService,
    CoreService,
    ImagesService,
    SocketService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
