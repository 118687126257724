import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import moment from 'moment'
import { enumData } from 'src/app/core'
import { NotificationService } from 'src/app/services'

@Component({
  selector: 'app-header-news',
  templateUrl: './header-news.component.html',
  styleUrls: ['./header-news.component.scss'],
})
export class HeaderNewsComponent implements OnInit {
  companyAddress: string = enumData.SettingString.COMPANY_ADDRESS.default
  companyPhone: string = enumData.SettingString.COMPANY_PHONE.default
  companyEmail: string = enumData.SettingString.COMPANY_EMAIL.default
  constructor(private router: Router, protected notificationService: NotificationService) {}
  currentDate!: any
  currentDay!: string
  currentMonth!: string
  currentYear!: string
  async ngOnInit() {
    this.currentDate = moment(new Date()).format('DD/MM/YYYY').toString().slice(0, 2)
    this.currentDay = new Date().toLocaleDateString('vi-US', { weekday: 'long' })
    this.currentMonth = new Date().toLocaleDateString('vi-US', { month: 'long' })
    this.currentYear = new Date().getFullYear().toString()
  }

  handleNavigate(id: string) {
    this.router.navigate(['news', id])
  }

  handleNavigateTo(data: string) {
    this.router.navigate([`${data}`])
  }

  redirectDuckRace() {
    window.open(
      'https://www.online-stopwatch.com/html5/race-timers-clock-mode/duck-race-1000/?characters=40&countdown=00:00:30&characterStyle=0',
    )
  }
}
