import { Injectable } from '@angular/core'
import { environment } from '../environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { BehaviorSubject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class ApiService {
  headers!: HttpHeaders
  eventLoadNotify = new BehaviorSubject<boolean>(false)
  constructor(private httpClient: HttpClient) {}

  private generateHeaders() {
    const userInfo = JSON.parse(localStorage.getItem('user') || '')
    const token = userInfo.access_token
    this.headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    })
  }

  UPLOAD_FILE = {
    UPLOAD_SINGLE: environment.baseUrl + 'uploadFiles/upload_single',
    UPLOAD_LIST: environment.baseUrl + 'uploadFiles/upload_list',
    // UPLOAD_SINGLE: environment.baseUrl + '/' + 'cloudinary/uploads',--> maximum 5 images (not tested yet | untested)file[]
  }

  async uploadFile(documentUpload: FormData) {
    // const formData = new FormData()
    // formData.append('blobFile', documentUpload.file)
    this.generateHeaders()

    return await new Promise((resolve, rejects) =>
      this.httpClient
        .post(this.UPLOAD_FILE.UPLOAD_SINGLE, documentUpload, {
          headers: this.headers,
        })
        .subscribe({
          error: err => {
            return rejects(err)
          },
          next: res => {
            return resolve(res)
          },
        }),
    )
  }

  async uploadFileList(documentUpload: FormData) {
    // const formData = new FormData()
    // formData.append('blobFile', documentUpload.file)
    this.generateHeaders()

    return await new Promise((resolve, rejects) =>
      this.httpClient
        .post(this.UPLOAD_FILE.UPLOAD_LIST, documentUpload, {
          headers: this.headers,
        })
        .subscribe({
          error: err => {
            return rejects(err)
          },
          next: res => {
            return resolve(res)
          },
        }),
    )
  }

  // post(url: string, data: any) {
  //   return new Promise((resolve, reject) => {
  //     const params = new HttpParams();
  //     const request = this.http.post(url, data);
  //     lastValueFrom(request)
  //       .then((res: any) => {
  //         resolve(res);
  //       })
  //       .catch((err: any) => {
  //         reject(err.response);
  //       });
  //   });
  // }
}
