import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { CoreService } from 'src/app/services'

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss'],
})
export class HomeLayoutComponent implements OnInit {
  isCollapsed = false
  isExpandMenu: string = ''
  mainTitle = 'Chúng tôi là cầu nối giữa ý tưởng và thành công'
  mainDescription = ''
  constructor(private router: Router, private coreService: CoreService) {}
  currentUser: any = {}
  setRole = new Set()

  ngOnInit(): void {}

  handleLogout() {}

  changePassword() {}

  handleExpand() {}

  handleNavigateTo(data: string) {
    this.router.navigate([`/${data}`])
  }

  // responsive
  //drawer
  visible = false

  open(): void {
    this.visible = true
  }

  close(): void {
    this.visible = false
  }
  panels = [
    {
      active: false,
      name: 'Lĩnh vực kinh doanh',
      disabled: false,
    },
  ]
}
