<div class="footer">
  <div nz-row class="footer__content">
    <div nz-col nzSpan="24" class="footer__content__top">
      <div nz-row class="footer__content__top__item">
        <div nz-col nzSpan="6" class="footer__content__top__item__left">
          <h3 class="footer__content__top__item__left__title">Mạng xã hội</h3>
          <div style="display: flex; gap: 4px">
            <div class="footer__content__top__item__left__title__icon">
              <a href="https://www.facebook.com/profile.php?id=61553143814539" target="_blank">
                <fa-icon size="2x" [icon]="faFacebook" />
              </a>
            </div>
            <div class="footer__content__top__item__left__title__icon">
              <fa-icon size="2x" [icon]="faLinkedin" />
            </div>
          </div>
        </div>
        <div nz-col nzSpan="6" class="footer__content__top__item__right">
          <h3 class="footer__content__top__item__right__title">Liên kết</h3>
          <div class="link-container">
            <a class="footer-text-hover" (click)="handleNavigateTo('about')">Về chúng tôi</a>
            <a class="footer-text-hover" (click)="handleNavigateTo('business')">Dịch vụ</a>
            <a class="footer-text-hover" (click)="handleNavigateTo('news')">Tin tức</a>
            <a class="footer-text-hover" (click)="handleNavigateTo('hiring')">Tuyển dụng</a>
            <a class="footer-text-hover" (click)="handleNavigateTo('contact')">Liên hệ</a>
          </div>
        </div>
        <div nz-col nzSpan="6" class="footer__content__top__item__right">
          <h3 class="footer__content__top__item__right__title">Liên hệ</h3>
          <div class="link-container">
            <a class="cursor-none">
              {{ companyAddress }}
            </a>
            <a class="cursor-none">+84 {{ companyPhone }}</a>
            <a class="cursor-none">{{ companyEmail }}</a>
          </div>
        </div>
        <div nz-col nzSpan="6" class="footer__content__top__item__right d-flex flex-row-reverse">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3295.519439371604!2d106.66778444978482!3d10.807086998464147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529246d3d8df5%3A0xda8ba809d0d8da57!2zNjYvOSDEkC4gUGjhu5UgUXVhbmcsIFBoxrDhu51uZyAyLCBUw6JuIELDrG5oLCBUaMOgbmggcGjhu5EgSOG7kyBDaMOtIE1pbmgsIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1705028083141!5m2!1svi!2s"
            width="250"
            height="200"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
    <h5 nz-col nzSpan="24" class="footer__content__copyright">
      <span nz-icon nzType="copyright" nzTheme="outline"></span>
      Phát triển bởi EcomZone Software Solution
    </h5>
  </div>
</div>
