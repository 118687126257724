import { Injectable } from '@angular/core'
import JSZip from 'jszip'
const fs = require('file-saver')

@Injectable()
export class ImagesService {
  constructor() {}

  /** Lấy số ngày trong tháng */
  public async downloadZip(imageUrls: string[], fileName?: string) {
    const zip = new JSZip()

    // Lặp qua mảng các URL hình ảnh
    for (let i = 0; i < imageUrls.length; i++) {
      const imageUrl = imageUrls[i]
      console.log(imageUrl)
      // Lấy phần mở rộng của URL hình ảnh
      const extension = imageUrl.split('.').pop()

      // Tải hình ảnh từ URL
      const response = await fetch(imageUrl)
      const imageBlob = await response.blob()

      // Lưu hình ảnh vào tệp tin zip
      zip.file(`image${i + 1}.${extension}`, imageBlob)
    }

    // Tạo tệp tin zip và tải xuống
    const nameFile = `${fileName || 'images'}-${new Date().toISOString()}.zip`
    zip.generateAsync({ type: 'blob' }).then(blob => fs.saveAs(blob, nameFile))
  }


  public async downloadZipFile(imageUrls: string[], fileName?: string) {
    const zip = new JSZip()

    // Lặp qua mảng các URL hình ảnh
    for (let i = 0; i < imageUrls.length; i++) {
      const imageUrl = imageUrls[i]
      // Lấy phần mở rộng của URL hình ảnh
      const extension = imageUrl.split('.').pop()

      // Tải hình ảnh từ URL
      const response = await fetch(imageUrl)
      const imageBlob = await response.blob()

      // Lưu hình ảnh vào tệp tin zip
      zip.file(`file${i + 1}.${extension}`, imageBlob)
    }

    // Tạo tệp tin zip và tải xuống
    const nameFile = `${fileName || 'file'}-${new Date().toISOString()}.zip`
    zip.generateAsync({ type: 'blob' }).then(blob => fs.saveAs(blob, nameFile))
  }
}
